/* @import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap"); */

* {
  margin: 0;
  padding: 0;
  touch-action: pan-x pan-y;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}
body {
  background-color: rgb(0, 0, 0);
  /* position: static; */
  overflow-y: scroll;
  overflow-x: hidden;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  margin: 0;
  padding: 0;
  color: rgb(255, 255, 255);
  user-select: none;
  -webkit-user-select: none;
  /* touch-action: none;
  overflow: hidden; */
  overscroll-behavior: contain;
}
@font-face {
  font-family: "Jost";
  src: url(/static/fonts/Aveny.woff);
  /* src: url(/static/fonts/EvilEmpire-4BBVK.ttf); */
  /* src: url(/static/fonts/7816Maiden.woff2); */
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "inter";
  src: url(/static/fonts/inter-700.woff2);
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "inter";
  src: url(/static/fonts/inter-400.woff2);
  font-style: normal;
  font-weight: 400;
}

.webgl {
  position: fixed;
  /* display: block; */
  height: 100vh;
  /* left: 50%;
  transform: translate(-50%, 0); */
  padding: 0;
  /* top: 0;
	left: 0;  */
  /* z-index: 99; */
  outline: none;
}

.scrollcontainer {
  position: absolute;
  height: 2000%;

  top: 0;
  left: 0;
  outline: none;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

/* .title {
  position: fixed;
  display: flex;
  bottom: 3%;
  left: 58%;
  color: rgb(255, 255, 255);
  font-family: "Jost";
  font-size: 94px;

  align-items: left;
  flex-direction: column;
  mix-blend-mode: exclusion;

} */

.title span {
  white-space: nowrap;
  font-size: 36px;
  opacity: 0;
}
.pawn {
  position: fixed;
  display: flex;
  bottom: 3%;
  left: 10%;
  color: rgb(255, 255, 255);
  font-family: "Jost";
  font-size: 94px;
  opacity: 0;
  align-items: left;
  flex-direction: column;
  mix-blend-mode: exclusion;
}
.scroll {
  opacity: 0;
}
.pawn span {
  white-space: nowrap;
  font-size: 36px;
}
.meta {
  position: fixed;
  display: flex;
  bottom: 3%;
  left: 10%;
  color: rgb(255, 255, 255);
  font-family: "Jost";
  font-size: 94px;
  opacity: 0;
  align-items: left;
  flex-direction: column;
  mix-blend-mode: exclusion;
}

.meta span {
  white-space: nowrap;
  font-size: 36px;
}

.vincent {
  mix-blend-mode: exclusion;
  position: fixed;
  display: flex;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Jost";
  font-size: 14vh;
  white-space: nowrap;
  flex-direction: column;
  text-align: center;
}
.vincent span {
  filter: blur(8px);
  opacity: 0;
  left: 50%;
  color: #fff;
}
.intro {
  position: relative;
  padding: 0 30px;
  width: 80%;
}
.intro-text {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  font-size: 10vh;

  justify-content: center;
  font-family: "Jost";
  color: rgb(255, 255, 255);

  display: flex;
  align-items: center;
  height: 100%;
  mix-blend-mode: exclusion;
  white-space: nowrap;
}

.intro-text span {
  opacity: 0;
  transform: translateY(40px);
}

.intro-text span:nth-child(even) {
  transform: translateY(-40px);
}

@-webkit-keyframes down {
  0% {
    transform: translateX(-6px);
  }
  100% {
    transform: translateX(6px);
  }
}

@keyframes down {
  0% {
    transform: translateX(-6px);
  }
  100% {
    transform: translateX(6px);
  }
}

.contact-container {
  position: fixed;
  display: block;
  opacity: 0;
  /* z-index: 6; */
  visibility: hidden;
}

.contact {
  position: fixed;
  display: block;
  top: 3%;
  /* z-index: 20; */
  font-family: "Jost";
}
.card {
  position: fixed;
  display: block;
  /* z-index: 5; */
  height: 120vh;
  overflow: hidden;
  width: min(100%, 777px);
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border-radius: 10px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  margin: 0 0;
}
.cardd {
  position: fixed;
  display: block;
  /* z-index: 10; */
  width: min(100%, 777px);
  transform: translate(-50%, -50%);
  left: 45%;
  top: 3%;
  visibility: hidden;
  opacity: 0;
  margin: 0 0;
}
.closecontact {
  position: fixed;
  display: block;
  top: 4%;
  right: 3%;
  cursor: pointer;
  color: #fff;
  /* z-index: 10; */
}

.shake {
  position: fixed;
  display: flex;
  top: 33%;
  bottom: 0;
  left: 2%;
  width: 100%;
  color: rgb(255, 255, 255);
  font-family: "Jost";
  height: 100%;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  text-transform: uppercase;
  /* z-index: 6; */
}

.shake span {
  white-space: nowrap;
  font-size: 36px;
}
.lets {
  position: fixed;
  top: 20%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  font-size: 38px;
  justify-content: center;
  font-family: "Jost";
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
  /* z-index: 6; */
  opacity: 0;
}

.conectbtn {
  position: fixed;
  cursor: pointer;
  top: 8%;
  right: 2%;
  float: right;
  padding-top: 2%;
  padding-right: 2%;
  font-size: 22px;
  font-family: "Jost";
  color: #fff;
  text-transform: uppercase;
  /* z-index: 13; */
  /* mix-blend-mode: exclusion; */
  white-space: nowrap;
  /* transform: scale(1.2);
  transform-origin: top right; */
  z-index: 21;
}
.connectLeft {
  position: fixed;
  display: none;
  float: left;
  cursor: pointer;
  left: -10%;
  /* top: -20vh; */
  right: auto;
  width: auto;
  font-size: 22px;
  font-family: "Jost";
  color: #fff;
  text-transform: none;
  white-space: nowrap;
  /* margin-left: -59%; */
  /* margin-left: -25%;
  margin-top: -25%; */
  background-color: #00000021;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(5.2px);
  padding: 10px;
  border-radius: 10px;
  mix-blend-mode: unset;
  z-index: 21;
  visibility: hidden;
  opacity: 0;
  /* transform: translateX(-100%); */
}
.generate {
  margin-top: 5px;
  margin-bottom: 5px;
}
.enableDrag {
  font-size: 20px;
}
.dragMe {
  font-size: 15px;
  color: var(--w3m-accent-color);
  /* float: right; */
  /* display: block; */

  text-align: right;
}
.whatAppStates,
.referralCounts {
  font-size: 15px;
  color: #a8a8a8;
}
.headerAndTokens {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 5;
}
.headerConnectWallet {
  position: fixed;
  top: 2%;
  /* right: 4%; */
  width: 100%;
  transform: scale(1); /* 1.3 */
  /* transform-origin: top right; */
  z-index: 20;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.goldPesaLogo {
  position: relative;
  flex-grow: 1;
  /* display: flex; */
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 2%;
  /* align-items: center; */
}
.goldPesaLogo img {
  position: relative;
  /* max-width: 150px; */
  /* max-width: 123px; */
  max-width: 110px;
  cursor: pointer;
  /* padding-right: 5px; */
  top: 8px;
}
.connectedHeader {
  position: relative;
  /* top: 0;
  right: 0; */
  /* width: 100%; */
  width: fit-content;
  flex-basis: content;
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: #ffffff00;
  box-shadow: inset 0 0 0 1px #ffffff1a;
  border-radius: 10px;
  padding: 4px 4px 4px 8px;
  /* margin-right: 2%; */
  transform: translateX(-6px);
  font-size: 18px;
  font-family: "Jost";
  white-space: nowrap;
  /* visibility: hidden; */
  /* background: rgba(0, 0, 0, 0.329); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}
.connectedAddrssHeader {
  border-radius: 28px;
  padding: 4px 8px 4px 4px;
  height: auto;
  margin-left: 10px;
  color: #000000;
  background-color: #f7b92e;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
}
.connectedUserAddress {
  padding: 1px 0px;
}
.connectedTokenHeader {
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
  /* margin-right: -17px; */
  padding: 1px 0px;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  transition: 0.3s;
}
.addressAvataerCont {
  scale: 0.85;
  transform-origin: right;
  display: none;
  min-width: fit-content;
}
.connectedTokenHeader:hover {
  transform: scale(1.02);
}
.chainImage {
  width: 28px;
  height: 28px;
  margin-right: 6px;
}
.chainImage div {
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
.chainImage div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.nftCountHeader {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-style: normal;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 76%);
}

#gui {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1000;
}

.dg.ac {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: 100 !important;
}

.mainProgressContainer {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  height: 35vh;
  width: 95%;
  max-width: 440px;
  margin-left: 2.5%;
  margin-right: 2.5%;
  overflow: hidden;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  background: rgba(0, 0, 0, 0.329);
  background: rgba(0, 0, 0, 0.39);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.2px);
  -webkit-backdrop-filter: blur(12.2px);
  /* background: #040611e3; */
  /* background: #040611d9; */
  /* background: #040611a7; */
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  font-size: 20px;
  font-family: "Jost";
  transform: translateY(100%);
  z-index: 1000;
  visibility: hidden;
}
.mainProgressContainerConnected {
  visibility: hidden;
  transform: translateY(100%);
  height: 58vh;
  height: 75vh;
  background: rgba(0, 0, 0, 0.39);
}
.levelsOverlay {
  position: fixed;
  width: 100%;
  top: 0;
  height: 0;
  overflow: auto;
  height: 100%;
  /* margin: -9px 0px 0px -9px; */
  border-radius: 15px 15px 0 0;
  background: var(--main-background-solid);
  background: #04061100;
  backdrop-filter: blur(0px);
  visibility: hidden;
  z-index: 999;
  /* pointer-events: none; */
  /* transform: translateX(15%); */
}
/* .mainProgressContainerNotConnected {
  bottom: -10vh;
}  */
.mainProgressContainerConnected:before {
  content: "";
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  /* background: linear-gradient(to bottom, #ffcb0f, #fff0 45%); */
  /* background: linear-gradient(to bottom, #3c3c3c, #fff0 45%); */
  mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask-composite: xor;
  mask-composite: xor;
  z-index: 5;
}
.mainProgressContainerNotConnected .credit {
  position: relative;
  /* display: flex;
  align-items: center; */
  width: 100%;
  height: 4.95vh;
  min-height: 60px;
  padding: 0px 0 0 10px;
  background-color: rgba(0, 0, 0, 0.322);
  background: rgb(4 6 17 / 68%);
  background: rgba(4, 6, 13, 0.64);
  font-size: 22px;
  height: 7vh;
  padding: 10px 0 0 10px;
}
.mainProgressContainerConnected .credit {
  position: relative;
  /* display: flex;
  flex-flow: column;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center; */
  width: 100%;
  height: 7vh;
  height: 6vh;
  height: 60px;
  min-height: 45px;
  padding: 10px 0 0 10px;
  background-color: rgba(0, 0, 0, 0.322);
  background: rgb(4 6 17 / 68%);
  background: rgba(4, 6, 13, 0.64);
  /* background-color: #040611; */
  /* background-color: #0c1121; */
  font-size: 22px;
  /* font-family: "inter"; */
  /* font-size: 18px; */
  cursor: grab;
}
.creditText {
  padding-top: 2vh;
  padding-top: 1vh;
  position: relative;
  left: 0;
}
.creditWindowDash {
  position: relative;
  margin: auto;
  margin-bottom: 0.9vh;
  height: 5px;
  width: 28px;
  border-radius: 45px;
  background: #616161;
}
.mainProgressContainerNotConnected .credit .creditWindowDash {
  transition: 0.3s;
}
.mainProgressContainerNotConnected .credit:hover .creditWindowDash {
  background: white;
  scale: 1.3;
}
.mainProgressBar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  width: 5%;
  flex: 5%;
  height: 27vh;
  height: 28%;
  overflow: hidden;
  /* bottom: 1vh; */
  /* left: 1vw; */
  /* padding-right: 1vw; */
  /* background-color: rgb(255, 217, 0); */
}
.miniMainProgressBar {
  position: absolute;
  /* top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%); */
  margin: auto;
  right: 0;
  height: 85%;
  width: 30%;
  border-radius: 16px;
  top: 5%;
}
.mainProgressBarGray {
  background: rgb(92, 92, 92);
}
.mainProgressBarActive {
  background: white;
  background: #34eb58;
  box-shadow: 0 1px 4px 1px #26b562, inset 0 0 0 1px #ffffff1a;
  height: 0%;
  transform-origin: top;
  top: 5%;
  z-index: 2;
}
.mainProgressContainerConnected .mainProgressBar .mainProgressBarGray {
  height: 75%;
  height: 90%;
}
.levels {
  position: relative;
  float: right;
  flex: 95%;
  width: 95%;
  height: 100%;
  /* right: 1vw; */
  /* background-color: salmon; */
  overflow: auto;
}
.levels::-webkit-scrollbar {
  display: none;
}
.level {
  position: relative;
  display: flex;
  width: 96%;
  height: 7.5vh;
  height: 64px;
  /* min-height: 60px; */
  margin: 2%;
  margin: 8px;
  background-color: rgba(0, 0, 0, 0.185);
  background: rgba(0, 0, 0, 0.329);
  background: rgb(4 6 17 / 68%);
  background: rgba(4, 6, 13, 0.64);
  /* background-color: #040611; */
  /* background-color: #0c1121; */
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s;
}
/* .level:before {
  content: "";
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(130deg, rgb(123 119 228 / 69%), rgba(255, 255, 255, 0) 40%);
  background: linear-gradient(160deg, #ffcb0f, rgba(255, 255, 255, 0) 30%);
  background: linear-gradient(160deg, #343434, rgba(255, 255, 255, 0) 30%);
  -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask-composite: xor;
}
.level:after {
  content: "";
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(332deg, rgb(123 119 228 / 69%), rgba(255, 255, 255, 0) 40%);
  background: linear-gradient(341deg, #ffcb0f, rgba(255, 255, 255, 0) 30%);
  background: linear-gradient(341deg, #343434, rgba(255, 255, 255, 0) 30%);
  -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask-composite: xor;
} */
.level:hover:not(.level14) {
  /* border-radius: 10px; */

  background: rgba(4, 6, 17, 1) !important;
  transform: scale(1.02) !important;
}

.tokensContainer {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  height: 50vh;
  /* width: 60%; */
  /* max-width: 300px; */
  width: -webkit-fill-available;
  max-width: 264px;
  right: 0%;
  top: calc(2% + 32px);
  margin-top: 0%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  /* overflow: hidden; */
  border-radius: 10px 10px 0 0;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  font-size: 20px;
  font-family: "Jost";
  z-index: 19;
  transform: translateX(110%);
  visibility: hidden;
}
.mainProgressContainerNotConnected .levels .level14 {
  background-color: #ffcc0f9c;
  background: linear-gradient(rgb(0 0 0 / 68%), rgb(0 0 0 / 13%)),
    linear-gradient(calc(var(--btn-grad-red-deg) * 1deg), #1f1500, #fca40a 50%, #1f1500);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000bf inset;
  border-radius: 8px;
  border: solid 2px transparent;
  --btn-grad-red-deg: 338;
}
.mainProgressContainerNotConnected .levels .level14:hover {
  /* background: #ffcc0f; */
  --btn-grad-red-deg: 20;
  transform: scale(1.02) !important;
}
.mainProgressContainerNotConnected .levels .level14 .levelProgress .levelInfo {
  color: rgb(255, 253, 253);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.mainProgressContainerNotConnected .levels .level14 .levelProgress .levelProgressBar {
  display: none;
}
.mainProgressContainerNotConnected .levels .level14 .levelPrize {
  background-color: #00000045;
}
.tokenContSecond {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.totalWinnings {
  position: relative;
  top: 20px;
  margin: 2% 3% 2% 3%;
  padding-top: 0;
  font-family: "inter";
  font-size: 20px;
  z-index: 3;
}
.totalWinningsHeader {
  cursor: pointer;
  transition: 0.3s;
}
.totalWinningsHeader:hover {
  transform: scale(1.02);
}
.totalWinningsText {
  font-size: 18px;
  background: linear-gradient(285.49deg, rgb(245, 55, 249) -14.61%, rgb(247, 190, 43) 106.06%);
  background: linear-gradient(90deg, #ff4d4d, #f9cb28);
  /* background: radial-gradient(
    100% 283.68% at 100% 0%,
    rgb(252, 201, 20) 0%,
    rgb(254, 111, 55) 47.95%,
    rgb(198, 106, 221) 75.34%,
    rgb(154, 116, 241) 100%
  ); */
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.tokenSorting {
  font-size: 14px;
  cursor: pointer;
  /* color: gray; */
  padding-left: 1px;
}
.tokenSortingArrow svg {
  width: 10px;
  height: 10px;
  fill: white;
  stroke-width: 2px;
  stroke: white;
}
.tokens {
  position: relative;
  float: right;
  flex: 100%;
  width: 100%;
  height: 100%;
  padding-top: 0;
  overflow: auto;
  overflow-x: hidden;
  mask-image: linear-gradient(180deg, transparent 5%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 70%, transparent 95%);
  -webkit-mask-image: linear-gradient(
    180deg,
    transparent var(--maskImageTrans),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 70%,
    transparent 95%
  );
  --maskImageTrans: 5%;
  transform: translate3d(0, 0, 0);
  /* cursor: pointer; */
}
.token {
  position: relative;
  display: flex;
  width: auto;
  /* height: 8vh; */
  min-height: 70px;
  max-height: 80px;
  margin: 2% 0 2% 2%;
  background-color: rgba(0, 0, 0, 0.185);
  border-radius: 10px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.329);
  /* background: #040611e3; */
  background: rgb(4 6 17 / 68%);
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);*/
  backdrop-filter: blur(12.2px);
  -webkit-backdrop-filter: blur(12.2px);
  transition: 0.3s;
}
/* .token:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(5.2px);

  z-index: -1;
  content: "";
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(130deg, rgb(123 119 228 / 69%), rgba(255, 255, 255, 0) 40%);
  background: linear-gradient(160deg, #ffcb0f, rgba(255, 255, 255, 0) 30%);
  background: linear-gradient(160deg, #343434, rgba(255, 255, 255, 0) 30%);
  -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask-composite: xor;
}
.token:after {
  content: "";
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(332deg, rgb(123 119 228 / 69%), rgba(255, 255, 255, 0) 40%);
  background: linear-gradient(341deg, #ffcb0f, rgba(255, 255, 255, 0) 30%);
  background: linear-gradient(341deg, #343434, rgba(255, 255, 255, 0) 30%);
  -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask-composite: xor;
} */

.greenToken {
  background: rgb(6 20 0 / 50%);
  /* background: rgb(29 23 3 / 50%); */
}
.tokens::-webkit-scrollbar {
  display: none;
}
.token:hover {
  border-radius: 10px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
  background: rgba(4, 6, 17, 1);
  transform: scale(1.02);
  transform-origin: right;
}
.greenToken:hover {
  background: rgb(6 20 0 / 70%);
  /* background: rgb(29 23 3 / 70%); */
}
.emptySpace {
  position: relative;
  display: flex;
  width: 96%;
  height: 16.5vh;
  /* height: 35.5vh; */
  margin: 2%;
  overflow: hidden;
}
.mainProgressContainerConnected .levels .emptySpace {
  position: relative;
  display: flex;
  width: 96%;
  height: 16.5vh;
  height: 35.5vh;
  height: 53vh;
  margin: 2%;
  overflow: hidden;
}
.emptySpaceSmaller {
  height: 2vh;
}
.levelProgress {
  position: relative;
  flex: 80%;
}
.mainProgressContainerNotConnected .levelInfo {
  position: relative;
  font-size: 20px;
  padding: 10px 0 0 10px;
}
.mainProgressContainerNotConnected .levels .level14 .levelProgress .levelInfo {
  padding: 0 0 0 10px;
}
.mainProgressContainerConnected .levelInfo,
.tokensContainer .levelInfo {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 20px;
  /* padding: 2% 0 0 2%; */
}
.levelNoInfo {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.token .levelProgress .levelInfo .levelNoInfo:hover {
  scale: 1.1;
}
.Levelindecator {
  background-color: rgb(92, 92, 92);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.tokenIndecator {
  background-color: #02fe02;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  box-shadow: 0 1px 4px 1px #26b562, inset 0 0 0 1px #ffffff1a;
}
.CountPlayersInfo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.countstakeinfo {
  margin-left: auto;
  padding-right: 15px;
}
.token .levelProgress .levelInfo .CountPlayersInfo:hover {
  scale: 1.1;
}
.CountPlayersText {
  font-size: 18px;
  color: grey;
  white-space: pre-line;
  text-align: center;
}
.copyLinkText {
  cursor: pointer;
  position: relative;
  flex: 100%;
  width: 100%;
  /* padding-left: 2vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  text-align: center;
}
.copyLinkText:hover {
  cursor: pointer;
  background-color: #296211;
  background-color: var(--w3m-accent-color);
  /* background-color: #000000; */
  color: black;
}
.levelCompleted {
  position: relative;
  font-size: 15px;
  padding: 2% 0 0 10px;
  opacity: 0;
  color: burlywood;
}
.levelProgressBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5%;
  bottom: 0;
}
.miniLevelProgressBar {
  position: absolute;
  /* top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%); */
  margin: auto;
  /* right: 0; */
  height: 5%;
  width: 100%;
  border-radius: 16px;
  bottom: 0;
}
.levelProgressBarGray {
  background: rgb(92, 92, 92);
}
.levelProgressBarActive {
  background: white;
  box-shadow: #26b562 0px 1px 4px 1px, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  width: 0%;
  transform-origin: top left;
  left: 0;
  /* top: 5%; */
  z-index: 2;
}
.levelPrize {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex: 20%;
  background: rgba(0, 0, 0, 0.295);
  /* background: #010104; */
  /* background: #040611; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.levelPrizeNone {
  display: none;
}
.token .levelPrize:hover {
  scale: 1.1;
}
.levelPrize {
  color: #2dbd7e;
}
.levelPrizeAmount {
  color: #2dbd7e;
}
.levelPrizetext {
  color: #808080;
  font-size: 18px;
}
/*explore nav*/
.StickyOverlay {
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
  /* mix-blend-mode: exclusion; */
  transform: translateX(-50%);
}
/* .StickyOverlay2 {
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
  transform: translateX(100%);
} */
.StickyOverlay2 {
  /* position: absolute; */
  width: 100%;
  z-index: 2;
  transform: translateX(150%);
}
.ExploreNav {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: hidden;
  top: 20px;
  bottom: 0;
  left: calc(env(safe-area-inset-left));
  z-index: 2;
  width: 50px;
  overflow: hidden;
  font-family: "Jost";
}
.ExploreNav2 {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: hidden;
  /* top: 0%; */
  /* bottom: -75vh;
  left: 0%; */
  /* top: calc(6vh + 35px);
  right: 0; */
  top: 35px;
  right: -5%;
  bottom: unset;
  left: unset;
  width: unset;
  z-index: 2;
  /* margin: auto; */
  /* width: auto; */
  overflow: hidden;
  font-family: "Jost";
  transform-origin: top center;
  transform: rotate(90deg) scale(1.7);
}

.ExploreNav-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 230px;
  padding-top: 60px;
  padding-bottom: 60px;
  /* mix-blend-mode: exclusion; */
}
.ExploreNav-inner2 {
  width: unset;
  padding: unset;
}
.ExploreNav a {
  margin-bottom: 31px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 26px;
  padding-left: 20px;
  position: relative;
  white-space: nowrap;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.ExploreNav2 a {
  padding-right: 20px;
}
a,
a:active,
a:hover,
a:visited {
  text-decoration: none;
}
.ExploreNav-line {
  position: absolute;
  height: 60px;
  top: 29px;
  left: 21px;
  width: 2px;
  overflow: hidden;
}
.ExploreNav-lineInner {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 2px;
  opacity: 0.7;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyIiBoZWlnaHQ9IjIiIHZpZXdCb3g9IjAgMCAyIDYiIGZpbGw9Im5vbmUiPjxjaXJjbGUgY3g9IjEiIGN5PSIxIiByPSIxIiBmaWxsPSIjNzg3NDZEIi8+PC9zdmc+");
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-size: 2px 6px;
  background-repeat: repeat-y;
}
.ExploreNav-lineProgress {
  position: absolute;
  height: calc(100% + 15px);
  top: 22px;
  left: 21px;
  width: 2px;
  overflow: hidden;
  background: var(--w3m-accent-color);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}
.ExploreNav-diamond {
  width: 4px;
  height: 4px;
  display: block;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  position: relative;
}
.outerDiamond {
  width: 11px;
  height: 11px;
  display: block;
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  top: -4px;
  left: -4px;
  z-index: -1;
  border: 1px solid rgba(255, 255, 255, 0.247);
}
.highlightDiamond {
  display: block;
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  top: -5px;
  left: -5px;
  opacity: 0;
  border: 2px solid var(--w3m-accent-color);
}
.highlightDiamondOuter {
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  top: -7px;
  left: -7px;
  opacity: 0;
  z-index: -1;
  border: 1px solid var(--w3m-accent-color);
}
.ExploreNav-diamond span {
  opacity: 0;
  display: block;
  width: 22px;
  text-align: center;
  position: absolute;
  font-size: 11px;
  /* line-height: 13px; */
  padding: 0px;
  top: 50%;
  left: -3px;
  /* -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums; */
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: rotateZ(-45deg) translate3d(0, -8.5px, 0);
  transform: rotateZ(-45deg) translate3d(0, -8.5px, 0);
  /* font-family: Rogan; */
  color: #ffffff;
}
.ExploreNav-diamond2 span {
  opacity: 0;
  display: block;
  width: 22px;
  text-align: center;
  position: absolute;
  font-size: 11px;
  /* line-height: 13px; */
  padding: 0px;
  top: 0;
  left: -8px;
  margin-top: -4px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  /* -webkit-transform: rotateZ(-45deg) translate3d(0,-8.5px,0); */
  transform: rotateZ(228deg);
  color: #ffffff;
}
.step3-2 {
  margin-bottom: 0px !important;
}
.innerDiamond {
  display: block;
  position: absolute;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: -1;
  width: 5px;
  height: 5px;
  background: #ffffff;
  top: 0;
  left: 0;
  /* will-change: transform;
  -webkit-transition: .3s;
  transition: .3s; */
  -webkit-transform: scale(1);
  transform: scale(1);
  /* mix-blend-mode: exclusion; */
}
.ExploreNav a > span {
  opacity: 0;
  padding-left: 24px;
  display: inline-block;
  font-size: 11px;
  margin-top: 0;
  font-family: Rogan;
  color: rgba(58, 48, 43, 0.8);
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.273;
}

.connectWallet {
  position: fixed;
  bottom: 0;
  max-width: 440px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%, 200%);
  /* transform: translateY(100%); */
}
.walletHeader {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: transparent;
  backface-visibility: hidden;
}
.headerPlaceholder {
  inset: 0px;
  width: 100%;
  position: absolute;
  display: block;
  pointer-events: none;
  height: 100px;
  border-radius: calc(8px * 0.9);
  background-color: #f7b92e;
  background-position: center center;
  background-size: cover;
}
.headerContent {
  height: 38px;
  display: flex;
  position: relative;
  margin: 5px 15px 5px 5px;
  justify-content: space-between;
  align-items: center;
}
.headerConnectText {
  height: 28px;
  object-position: left center;
  object-fit: contain;
  font-size: 25px;
  font-family: "Jost";
  color: #000000;
  pointer-events: none;
}
.headerClose {
  display: flex;
}
.headerClose button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: black;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.walletContent {
  border-radius: 30px 30px 0 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 6px 14px -6px rgba(10, 16, 31, 0.12), 0 10px 32px -4px rgba(10, 16, 31, 0.1), 0 0 0 1px rgba(255, 255, 255, 0.1);
  background-color: black;
  color: rgb(228, 231, 231);
  height: 160px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.walletBtn {
  margin: 20px 20px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  height: 95px;
  background-color: transparent;
}
.walletBtn:hover {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.17);
}
.walletBtnFlex {
  height: 100%;
  width: 80px;
  padding: 5px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.walletImage {
  height: 60px;
  width: 60px;
  border-radius: 15px;
  margin-bottom: 5px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.walletImageText {
  position: relative;
  z-index: 1;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 20px;
  font-family: "Jost";
  color: #fff;
  pointer-events: none;
}
/* w3m-modal {
  position: relative;
  z-index: 1004;
} */
.mine-card {
  width: 100%;
  position: relative;
  border-radius: var(--w3m-container-border-radius);
  overflow: hidden;
  box-shadow: 0 6px 14px -6px rgba(10, 16, 31, 0.12), 0 10px 32px -4px rgba(10, 16, 31, 0.1), 0 0 0 1px var(--w3m-color-overlay);
  background-color: var(--w3m-color-bg-1);
  color: var(--w3m-color-fg-1);
}
.mine-success {
  /* height: 16px; */
  width: max-content;
  max-width: 77vw;
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
  padding: 9px 15px 11px;
  position: fixed;
  top: 50%;
  box-shadow: rgba(10, 16, 31, 0.3) 0px 6px 14px -6px, rgba(10, 16, 31, 0.15) 0px 10px 32px -4px;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  backdrop-filter: blur(20px) saturate(1.8);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 36px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  /* inset: 0px; */
  z-index: 5000;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
}
.linkCopied {
  visibility: hidden;
}
.mine-success svg {
  /* margin-right: 5px; */
  width: 13px;
  height: 12px;
  flex: 0 0 auto;
}
.mine-success path {
  fill: var(--w3m-accent-color);
}
.mine-small-regular {
  color: rgb(228, 231, 231);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-transform: none;
  font-style: normal;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  font-feature-settings: "tnum", "lnum", "case";
  flex: 1;
}
/* start screens */
.fixedFull {
  position: fixed;
  width: 100%;
  height: 100vh;
  font-family: "inter";
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  gap: 1px;
  text-align: center;
  /* mix-blend-mode: exclusion; */
  /* bottom: 5vh; */
}
.hero {
  visibility: hidden;
}
.welocme2145 {
  position: relative;
  font-weight: 400;
  /* letter-spacing: -0.5px; */
  letter-spacing: 3.2px;
  font-size: 13px;
  color: gray;
  font-size: calc(min(max(13px, 3vw), 18px));
}
.largeMainText {
  position: relative;
  font-size: 47px;
  letter-spacing: -2px;
  line-height: 49px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-size: calc(min(max(41px, 11vw), 60px));
}
.goldRush {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #ff4d4d, #f9cb28);
  padding-bottom: 15px;
}
.redRush {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #ff2323, #f9cb28);
  /* filter: drop-shadow(-3px 2px 5px #0000008e); */
  padding-bottom: 8px;
}
.redRush2 {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(124.31deg, rgb(70, 227, 183) 0.18%, rgb(82, 124, 172) 89.2%);
  /* padding-bottom: 15px; */
}
.redRush3 {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(106.62deg, rgb(232, 78, 56) 6.68%, rgb(186, 44, 184) 82.69%);
  padding-bottom: 15px;
}
.redRush4 {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(129.06deg, rgb(154, 106, 255) 0%, rgb(96, 255, 199) 88.16%);
  padding-bottom: 15px;
}
.textShadow {
  font-size: 30px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3), 0px -4px 10px rgba(255, 255, 255, 0.3);
  text-shadow: 0 2px 2px #dfdfdf, -2px 5px 1px #b8b8b8, -4px 8px 0px #979797, -6px 11px 0px #747474, -8px 14px 0px #565656,
    -10px 17px 0px #343434, -12px 20px 0px #171717, -14px 23px 0px #000;
  text-shadow: 3px 0px 7px rgba(81, 67, 21, 0.8), -3px 0px 7px rgba(81, 67, 21, 0.8), 0px 4px 7px rgba(81, 67, 21, 0.8);
  text-shadow: -8px 6px 10px #000000cf;
  font-size: calc(min(max(27px, 7vw), 33px));
}
.textShadowInvert {
  font-size: 30px;
  color: #000;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3);
  font-size: calc(min(max(27px, 7vw), 33px));
  /* 0px -4px 10px rgba(255,255,255,0.3);
  text-shadow: 0 2px 2px #dfdfdf, 
    -2px 5px 1px #b8b8b8, 
    -4px 8px 0px #979797, 
    -6px 11px 0px #747474,
    -8px 14px 0px #565656,
    -10px 17px 0px #343434,
    -12px 20px 0px #171717,
    -14px 23px 0px #000;
    text-shadow: 3px 0px 7px rgba(81,67,21,0.8), -3px 0px 7px rgba(81,67,21,0.8), 0px 4px 7px rgba(81,67,21,0.8); */
}
.secondaryTop {
  justify-content: flex-start;
  top: 15vh;
  visibility: hidden;
}
.secondaryBottom {
  justify-content: flex-end;
  top: -5vh;
  visibility: hidden;
}
.secondaryCenter {
  justify-content: center;
  visibility: hidden;
}
.mediumMainText {
  position: relative;
  font-size: 28px;
  font-size: 40px;
  letter-spacing: -2px;
  line-height: 33px;
  text-align: center;
  text-align: left;
  /* line-height: 4vh; */
  padding-left: 3%;
  padding-right: 3%;
  text-overflow: ellipsis;
  max-width: 25ch;
  max-width: 16ch;
  font-size: calc(min(max(38px, 10vw), 42px));
}
.alignStart {
  align-items: start;
}
.marginTop {
  margin-top: -20px;
}
.mediumMainText span {
  display: block;
}
.screenPaddingBottom {
  padding-bottom: 40px;
}
.smallerLetterSpace {
  letter-spacing: -1px;
  font-size: calc(min(max(14px, 4vw), 18px));
}
.removePadding {
  padding-bottom: unset;
}
.gap {
  padding-bottom: 15px;
}

.mintBtn {
  position: fixed;
  bottom: 15vh;
  z-index: 20;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  /* left: 0; */
  right: 2.5%;
  font-family: "Jost";
  font-size: 18px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
}

.mainButton {
  all: unset;
  width: 150px; /* 150px; */
  height: 50px;
  /* padding-left: 20px;
  padding-right: 20px; */
  backdrop-filter: blur(20px) saturate(1.8);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.3);
  /* background: rgba(4, 6, 13, 0.4); */
  box-sizing: border-box;
  transition: 0.5s;
}
.mainButton:hover {
  background-color: #0000008f;
  --btn-grad-red-deg: 20;
}
.incrementButton,
.decrementButton {
  all: unset;
  width: auto; /* 50px; */
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  backdrop-filter: blur(20px) saturate(1.8);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.3);
  /* background: rgba(4, 6, 13, 0.4); */
  box-sizing: border-box;
}

.incrementButton:hover,
.decrementButton:hover {
  background-color: #0000008f;
  --btn-grad-red-deg: 20;
}
/* .radiusBTN {
  border-radius: 10px;
  overflow: hidden;
  margin-left: 0.5vh;
  margin-right: 0.5vh;
} */
.btnBorder {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(calc(var(--btn-grad-red-deg) * 1deg), #1f1500, #fca40a 50%, #1f1500);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000212 inset;
  border-radius: 8px;
  border: solid 2px transparent;
  --btn-grad-red-deg: 338;
  /* box-sizing: border-box;
  border: 2px solid;
  border-image: linear-gradient(calc(var(--btn-grad-red-deg) * 1deg), #1b1101,#fca40a 50%, #281900)1;
  border-image: linear-gradient(calc(var(--btn-grad-red-deg) * 1deg), #1b1101,#fca40a66 50%, #281900)1; */
}
.mainMintText {
  font-size: 16px;
  color: gray;
  display: block;
}
.gradientText {
  background: linear-gradient(285.49deg, rgb(245, 55, 249) -14.61%, rgb(247, 190, 43) 106.06%);
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.indBtnCont {
  display: flex;
  margin-top: 1vh;
}
.independentButton {
  margin-left: 0.5vh;
  margin-right: 0.5vh;
}
.dot {
  opacity: 0;
  display: inline-block;
  animation: none;
  color: var(--w3m-accent-color);
}
.successDiv {
  visibility: hidden;
}
.errorDiv {
  visibility: hidden;
  /* z-index: 2002; */
}
.errorDiv path {
  fill: #f50000;
}

/* .sort-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  bottom: 0;
  background: black;
  z-index: 3000;
} */
.mintOptionsoverlayFull,
.totalWinningsOverlayFull {
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  z-index: 2999;
  visibility: hidden;
}
.stakeConfirmationOverlayFull {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #000000a7;
  z-index: 3999;
  visibility: hidden;
  /* transform: translateY(100%); */
}
.minOptions-container,
.totalWinnings-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  padding: 20px;
  /* height: 50vh; */

  bottom: 0;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  background: #222630;
  z-index: 3000;
  width: 100%;
  font-family: "Jost";
  font-size: 18px;
  max-width: 440px;
  border-radius: 15px 15px 0 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  background: rgb(0 0 0 / 96%);
  background: var(--main-background-solid);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.2px);
  -webkit-backdrop-filter: blur(5.2px);
  transform: translateY(120%);
}
.totalWinnings-container {
  height: unset;
  min-height: 29vh;
  padding: unset;
}
.redGradient {
  background: linear-gradient(90deg, #ff4d4d, #f9cb28);
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-sizing: border-box;
  clip-path: inset(1px);
}
.totalWinningsHeaderModal {
  font-size: 25px;
  margin-bottom: 150px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}
.totalWinningsFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.totalNFTsModal,
.totalStakedAssetsModal,
.totalStakedPrincipleModal,
.totalStakedEarningsModal {
  font-size: 18px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.totalStakedPrincipleModal,
.totalStakedEarningsModal {
  font-size: 16px;
}
.totalStakedAssetsModal,
.totalStakedPrincipleModal {
  margin-bottom: 10px;
}
.totalNFTsTextModal,
.totalStakedAssetsTextModal,
.totalStakedPrincipleTextModal,
.totalStakedEarningsTextModal {
  color: var(--sec-text-color);
}
.tokensTotalAmountPercentage {
  background-color: #13192d;
  color: var(--w3m-accent-color);
  padding: 3px;
  margin-left: 5px;
  margin-top: -4px;
  /* font-size: 12px; */
  box-sizing: border-box;
  padding: 4px 4px 2px 4px;
  /* border-radius: 6px; */
  line-height: 12px;
}
.totalNFTsModal .tokensTotalAmountPercentage,
.totalStakedAssetsModal .tokensTotalAmountPercentage {
  font-size: 16px;
}
.totalStakedPrincipleModal .tokensTotalAmountPercentage,
.totalStakedEarningsModal .tokensTotalAmountPercentage {
  font-size: 14px;
}
.minOptions-container:before,
.totalWinnings-container:before {
  content: "";
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(to bottom, #ffcb0f, #fff0);
  mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask-composite: xor;
  mask-composite: xor;
  z-index: 5;
}
.stakeConfirmation-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  padding: 20px;
  /* height: 50vh; */
  min-height: 50vh;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  background: #222630;
  z-index: 4000;
  width: 100%;
  font-family: "Jost";
  font-size: 18px;
  max-width: 440px;
  border-radius: 15px 15px 0 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  background: rgb(0 0 0 / 96%);
  background: var(--main-background-solid);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.2px);
  -webkit-backdrop-filter: blur(5.2px);
  transform: translateY(110%);
}
.stakeConfirmation-container:before {
  content: "";
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(to bottom, #ffcb0f, #fff0);
  mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask-composite: xor;
  mask-composite: xor;
  z-index: 5;
}
.stakeConfirmFinal {
  position: absolute;
  bottom: 0;
  /* left: 0; */
  right: 0;
  margin: 10px 20px 10px 20px;
  width: 43%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 100%; */
  margin-left: 20px;
  margin-right: 20px;
  padding: 15px 10px;
  box-sizing: border-box;
  background: #222222;
  color: gray;
  background: #494949;
  color: #7a7878;
  background: #161b29;
  color: #333848;
  font-size: 20px;
  border-radius: 4px;
  transition: 0.3s;
  font-weight: 600;
  outline: none;
  border: 1px solid transparent;
  z-index: 1;
  cursor: not-allowed;
}
.stakeConfirmationText {
  font-size: 20px;
  color: var(--sec-text-color);
  line-height: 28px;
}
.stakeConfirmationAfterText {
  margin-bottom: 54px;
}
.stakeConfirmCancel {
  position: absolute;
  bottom: 0;
  left: 0;
  /* right: 0; */
  margin: 10px 20px 10px 20px;
  width: 43%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 100%; */
  margin-left: 20px;
  margin-right: 20px;
  padding: 15px 10px;
  box-sizing: border-box;
  /* background: #222222;
  color: gray;
  background: #494949;
  color: #7a7878;
  background: #161b29;
  color: #333848; 
  background: var(--w3m-accent-color); */
  color: white;
  font-size: 20px;
  border-radius: 4px;
  transition: 0.3s;
  font-weight: 600;
  outline: none;
  border: 1px solid var(--w3m-accent-color);
  z-index: 1;
  cursor: pointer;
}
.stakeConfirmFinalActive {
  background: var(--w3m-accent-color);
  color: black;
  cursor: pointer;
}
.stakeConfirmFinalActive:hover {
  transform: scale(1.02);
}
.stakeConfirmCancel:hover {
  transform: scale(1.02);
}
#StakeConfirmationInput {
  background-color: #161a29;
  width: -webkit-fill-available;
  color: white;
  font-size: 17px;
  margin-top: 20px;
  border: none;
  padding: 15px 10px;
  outline: none; /* This removes the blue focus border that browsers add by default */
}
#StakeConfirmationInput::placeholder {
  color: #333848;
  letter-spacing: 1px;
}
.mintOptionsHeadline {
  padding-bottom: 20px;
  font-size: 25px;
}
.mintOptionsText {
  font-size: 18px;
  color: var(--sec-text-color);
}
.mintOptionsBottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 20px 20px 20px 20px;
  width: -webkit-fill-available;
}
#gpm_output {
  /* width: -webkit-fill-available; */
  color: var(--sec-text-color);
  /* color: #f43131; */
  /* padding-top: 20px; */
  /* line-height: 21px; */
  text-align: center;
}
.mintOptionsCurrencyBtns {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  /*margin-bottom: 158px;*/
}
.mintOptionsBtn {
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(41, 50, 73);
  border: solid 1px rgb(41, 50, 73);
  opacity: 1;
  box-shadow: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  height: unset;
  border-radius: 16px;
  outline: none;
  user-select: none;
  font-size: 24px;
  font-weight: 500;
  width: initial;
  padding: 4px 8px 4px 4px;
  gap: 8px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  /* margin-left: 12px; */
  visibility: visible;
  box-sizing: border-box;
  margin: 0 5px;
}
.cashoutOptionsCurrencyBtns {
  display: flex;
  width: 100%;
  gap: 24px;
  margin-top: 5px;
  /* justify-content: space-around; */
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px 0px;
}
.cashoutOptionsGPO,
.cashoutOptionsUSDC {
  margin-left: unset;
}
.mintOptionsBtnActive {
  border: solid 1px #ffcc0f;
}
.mintOptionsImgCont {
  width: 24px;
  height: 24px;
  background: none;
  box-shadow: white 0px 0px 1px;
  border-radius: 50%;
}
.mintOptionsImg {
  opacity: 1;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.mintOptionsBtnText {
  padding-top: 6px;
}
#approveBtn {
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0;
  right: 0; */
  /* margin: 10px 20px 10px 20px; */
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 100%; */
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  margin-top: 20px;
  padding: 15px 10px;
  box-sizing: border-box;
  background: var(--w3m-accent-color);
  color: black;
  cursor: pointer;
  font-size: 20px;
  border-radius: 4px;
  transition: 0.3s;
  font-weight: 600;
  outline: none;
  border: 1px solid transparent;
  z-index: 1;
}
.mintBtnFinal {
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  /* margin: 10px 20px 10px 20px; */
  width: -webkit-fill-available;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 100%; */
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  padding: 15px 10px;
  box-sizing: border-box;
  background: #222222;
  color: gray;
  background: #494949;
  color: #7a7878;
  background: #161b29;
  color: #333848;
  font-size: 20px;
  border-radius: 4px;
  transition: 0.3s;
  font-weight: 600;
  outline: none;
  border: 1px solid transparent;
  z-index: 1;
  cursor: not-allowed;
}
.mintBtnFinalSpinner {
  position: absolute;
  opacity: 0;
}
.mintBtnFinalActive {
  background: var(--w3m-accent-color);
  color: black;
  cursor: pointer;
}
.mintBtnFinalActive:hover {
  transform: scale(1.02);
}
.overlayFull {
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  z-index: 2999;
  visibility: hidden;
}
.sortingContBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-left: 5px; */
  margin-left: 10px;
  margin-right: 5px;
  background: #ffffff1c;
  background-color: var(--w3m-color-av-1);
  background-image: radial-gradient(at 66% 77%, var(--w3m-color-av-2) 0, transparent 50%),
    radial-gradient(at 29% 97%, var(--w3m-color-av-3) 0, transparent 50%),
    radial-gradient(at 99% 86%, var(--w3m-color-av-4) 0, transparent 50%),
    radial-gradient(at 29% 88%, var(--w3m-color-av-5) 0, transparent 50%);
  border-radius: 100%;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
}
.sortingContBtn svg {
  width: 10px;
  height: 10px;
  fill: white;
  stroke-width: 1px;
  stroke: white;
}
.sortingContBtn svg path {
  fill: white;
}
.sorting-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  background: #222630;
  z-index: 3000;
  width: 100%;
  font-family: "Jost";
  font-size: 18px;
  max-width: 440px;
  border-radius: 15px 15px 0 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  background: rgb(0 0 0 / 96%);
  background: var(--main-background-solid);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.2px);
  -webkit-backdrop-filter: blur(5.2px);
  transform: translateY(110%);
}

.sort-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  transition: 0.3s;
}
.sort-option:hover {
  background: rgba(0, 0, 0, 0.7);
  background: rgba(36, 37, 50, 0.24);
  border-radius: 10px;
}
/* .sort-option:active {
  background: rgba(0, 0, 0, 0.7);
  background: rgb(30 30 30 / 70%);;
  border-radius: 10px;
} */
.sortHeadline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-size: 22px;
  padding: 15px 10px 15px 10px;
}
.sort-option label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.sort-option input[type="radio"] {
  appearance: none;
  width: 24px;
  height: 24px;
  border: 3px solid #b0b3b5;
  border-radius: 50%;
  outline: none;
  box-sizing: border-box;
  position: relative;
  background-color: transparent;
  margin-right: 10px;
  cursor: pointer;
}

.sort-option input[type="radio"]:checked {
  border: 3px solid #ffcc0f;
  background-color: transparent;
}

.sort-option input[type="radio"]:checked::after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 3px;
  background-color: #ffcc0f;
}

.labelText {
  color: white;
  cursor: pointer;
}
span.labelSecondText {
  display: block;
  font-size: 16px;
  color: gray;
  padding-top: 5px;
}
:root {
  --main-background-solid: #040611;
  --background-lighter: #0c1121; /* #0c1121 */
  --background-darker: rgb(4 5 16 / 46%);
  --background-lighter-hover: #0e1426;
  --card-gradient-background: linear-gradient(#00000000 0%, #0c111e 100%);
  --card-gradient-background2: linear-gradient(#00000000 0%, #0c111ed6 100%);
  --card-gradient-background-hover: linear-gradient(#0c111e 0%, #0c111e 100%);
  --card-box-shadow: #000000 -3px 3px 8px 0px;
  --sec-text-color: #5f6287;
}
.menu-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  background: #222630;
  z-index: 3000;
  width: 100%;
  max-width: 440px;
  /* max-height: 50vh; */
  max-height: 75vh;
  min-height: 215px;
  min-height: 75vh;
  font-family: "Jost";
  font-size: 18px;
  letter-spacing: 1px;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  background: rgb(0 0 0 / 96%);
  background: var(--main-background-solid);
  /* background: rgb(34 38 48); */
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(5.2px); */
  /* -webkit-backdrop-filter: blur(5.2px); */
  transform: translateY(110%);
  /* touch-action: auto !important; */
}
.menuScreen::-webkit-scrollbar {
  display: none;
}
.mainMenuScreen {
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.menuElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}
.menuElement:hover {
  transform: scale(1.02);
  transform-origin: left;
}
.menuLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menuArrow svg {
  width: 10px;
  height: 10px;
  fill: #70798a;
  stroke-width: 2px;
  stroke: #70798a;
  transform: rotate(270deg);
}
.menuLabelIcon svg {
  width: 25px;
  height: 25px;
  fill: #707989;
  /* filter: drop-shadow(3px 3px 8px black); */
  /* fill: #9199a5; */
  /* stroke-width: 2px;
  stroke: #70798a; */
}
.menuHeader {
  position: sticky;
  top: 0;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding: 15px 10px 5px 10px;
  margin-bottom: 15px; */
  padding: 15px 10px 62px 10px;
  margin-bottom: -42px;
  box-sizing: border-box;
  font-size: 16px;
  background-image: linear-gradient(to bottom, rgb(6 7 18) 62%, rgb(6 7 18 / 62%) 75%, rgb(0 0 0 / 0%));
  z-index: 2;
}
.headerLeft {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}
.notificationsIconCont {
  display: flex;
  /* gap: 10px; */
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.w3m-placeholder-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px var(--w3m-color-overlay);
  display: block;
  position: relative;
  background-color: var(--w3m-color-av-1);
  background-image: radial-gradient(at 66% 77%, var(--w3m-color-av-2) 0, transparent 50%),
    radial-gradient(at 29% 97%, var(--w3m-color-av-3) 0, transparent 50%),
    radial-gradient(at 99% 86%, var(--w3m-color-av-4) 0, transparent 50%),
    radial-gradient(at 29% 88%, var(--w3m-color-av-5) 0, transparent 50%);
  transform: translateZ(0px);
  overflow: hidden !important;
}
.menuHeaderBalance {
  color: var(--sec-text-color);
}
.menuHeaderBalance span {
  color: white;
}

.notificationsIconCont svg {
  width: 30px;
  height: 30px;
  fill: #707989;
  cursor: pointer;
  /* fill: #9199a5; */
}
/* .notificationsIconCount {
  position: absolute;
  display: block;
  background: red;
  padding: 4px;
  margin-top: -28px;
  margin-left: 9px;
  width: 20px;
  height: 20px;
  font-family: "Jost";
  font-size: 15px;
  border-radius: 100%;
  text-align: center;
  box-sizing: border-box;
} */
.notificationsIconCount {
  position: absolute;
  /* display: none; */
  background: red;
  padding: 4px;
  margin-top: -28px;
  margin-left: 15px;
  width: 10px;
  height: 10px;
  font-family: "Jost";
  font-size: 15px;
  border-radius: 100%;
  text-align: center;
  box-sizing: border-box;
}
.notificationsIconCountMainHeader {
  position: absolute;
  background: red;
  padding: 4px;
  margin-top: -20px;
  margin-left: 26px;
  width: 20px;
  height: 20px;
  font-size: 15px;
  border-radius: 100%;
  text-align: center;
  box-sizing: border-box;
}
.menuHeaderLine {
  position: sticky;
  top: 71px;
  /* display: flex; */
  width: 95%;
  height: 1px;
  background: #414141;
  background: #282e3f7a;
  border-radius: 7px;
  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  align-self: center;
  z-index: 3;
}
.stakeMenuScreen {
  position: relative;
  width: 100%;
  /* min-height: 0; */
  top: 0;
  height: 0px;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
}
.cashoutMenuScreen,
.stakeMenuScreen,
.transactionMenuScreen {
  position: relative;
  width: 100%;
  /* min-height: 0; */
  top: 0;
  height: 0px;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
}
.menuBack {
  /* position: relative; */
  position: sticky;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 22px;
  padding: 15px 10px 25px 10px;
  margin-bottom: -7px;
  background-image: linear-gradient(to bottom, rgb(6 7 18) 55%, rgb(6 7 18 / 62%) 75%, rgb(0 0 0 / 0%));
  z-index: 2;
}
.menuBackTransaction {
  /* position: relative; */
  position: sticky;
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: center;
  top: 0;
  /* width: 100%; */
  /* box-sizing: border-box; */
  /* font-size: 22px; */
  /* padding: 15px 10px 25px 10px; */
  margin-bottom: -7px;
  background-image: linear-gradient(to bottom, rgb(6 7 18) 55%, rgb(6 7 18 / 62%) 75%, rgb(0 0 0 / 0%));
  padding-bottom: 35px;
  margin-bottom: -30px;
  background-image: linear-gradient(to bottom, rgb(6 7 18) 80%, rgb(6 7 18 / 62%) 93%, rgb(0 0 0 / 0%));
  z-index: 2;
}
.menuBackHeader {
  display: flex;
  /* top: 0; */
  width: 100%;
  box-sizing: border-box;
  font-size: 22px;
  padding: 15px 10px 25px 10px;
}
.backSvg {
  width: 20px;
  height: 20px;
  fill: #70798a;
  stroke-width: 1px;
  stroke: #70798a;
  flex-grow: 0;
  flex-shrink: 0;
  transform: rotate(270deg) scaleX(0.8);
  transition: 0.3s;
  cursor: pointer;
}
.backSvg:hover {
  transform: rotate(270deg) scaleX(0.9) scaleY(1.1);
}
.backText {
  flex-grow: 1;
  text-align: center;
}
.stakeMenuContent {
  display: flex;
  flex-flow: column;
  gap: 20px;
  /* padding-bottom: 30px; */
  /* margin-top: 50px; */
  /* max-height: 75vh; */
  /* padding-top: 55px; */
  /* margin-top: -35px; */
  /* padding-bottom: 20vh; */
  /* overflow: auto; */
  /* mask-image: linear-gradient(180deg, transparent 5%, rgba(0, 0, 0, 1) 20%); */
  /* -webkit-mask-image: linear-gradient(180deg, transparent 5%, rgba(0, 0, 0, 1) 20%); */
}
.cashoutMenuContent {
  display: flex;
  flex-flow: column;
  /* height: 100%; */
  gap: 20px;
  padding-bottom: 30px;
}
.transactionMenuContent {
  padding-bottom: 30px;
}
.stakeDurationsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px 0px 10px;
  box-sizing: border-box;
  /* background: #222222; */
  color: gray;
  color: var(--sec-text-color);
  font-size: 17px;
  border-radius: 4px;
  transition: 0.3s;
}
.stakeDurationsBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  /* background: #222222; */
  font-size: 17px;
  /* border-radius: 4px; */
  transition: 0.3s;
  text-align: center;
}
.stakeDurationsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222222;
  background: var(--background-lighter);
  color: white;
  flex: 1;
  padding: 6px 25px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  /* box-shadow: var(--card-box-shadow); */
  border: 1px solid transparent;
}
.stakeDurationsBtn:hover {
  background: var(--background-lighter-hover);
}
.stakeDurationsBtnActive {
  border-color: var(--w3m-accent-color);
}
.stakeEstAPR,
.stakeFlatFee {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  padding: 10px 10px 0px 10px;
  box-sizing: border-box;
  /* background: #222222; */
  color: gray;
  color: var(--sec-text-color);
  font-size: 16px;
  border-radius: 4px;
  transition: 0.3s;
}
.stakeEstAPR span,
.stakeFlatFee span {
  padding-left: 7px;
  color: white;
}
.cashoutTotalBalance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 20px 10px 0px 10px;
  box-sizing: border-box;
  /* background: #222222; */
  color: gray;
  color: var(--sec-text-color);
  font-size: 16px;
  border-radius: 4px;
  transition: 0.3s;
}
.cashoutTotalBalance div {
  display: flex;
  flex-flow: column;
  gap: 3px;
}
.cashoutTotalBalance span {
  /* padding-left: 7px; */
  color: white;
}
.stakeMenuSelectbtn,
.transactionMenuSelectbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */

  margin-left: 10px;
  margin-right: 10px;
  /* margin-top: 20px; */
  padding: 15px 10px;
  box-sizing: border-box;
  background: #222222;
  background: var(--background-lighter);
  color: var(--sec-text-color);
  border-radius: 4px;
  box-shadow: var(--card-box-shadow);
  transition: 0.3s;
  cursor: pointer;
}
.transactionMenuSelectbtn {
  margin-top: 15px;
}
.stakeMenuSelectbtn:hover,
.transactionMenuSelectbtn:hover {
  background: var(--background-lighter-hover);
  transform: scale(1.02);
}
.cashoutMenuSelectbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */

  margin-left: 10px;
  margin-right: 10px;
  padding: 15px 10px;
  box-sizing: border-box;
  background: #222222;
  background: var(--background-lighter);
  color: var(--sec-text-color);
  border-radius: 4px;
  box-shadow: var(--card-box-shadow);
  transition: 0.3s;
  cursor: pointer;
}
.cashoutMenuSelectbtn:hover {
  background: var(--background-lighter-hover);
  transform: scale(1.02);
}
.SelectBtnLabel {
  position: relative;
  display: flex;
  /* align-items: center; */
  gap: 10px;
}
.SelectGreenIndecator,
.transactionSelectGreenIndecator {
  height: 16px;
  width: 5px;
  background: green;
  border-radius: 2px;
  display: none;
}
.cashoutSelectGreenIndecator {
  height: 16px;
  width: 5px;
  background: green;
  border-radius: 2px;
  display: none;
}
.cashoutCurrencyOptitions {
  display: none;
  padding: 20px 10px 0px 10px;
}
.stakeTokensScreen,
.transactionTokensScreen {
  position: relative;
  width: 100%;
  top: 0;
  height: 0;
  visibility: hidden;
  overflow: auto;
}
.stakeTokensContent {
  padding-bottom: 30px;
  /* max-height: 75vh; */
  /* padding-top: 55px; */
  /* margin-top: -35px; */
  /* overflow: auto; */
  /* mask-image: linear-gradient(180deg, transparent 5%, rgba(0, 0, 0, 1) 20%); */
  /* -webkit-mask-image: linear-gradient(180deg, transparent 5%, rgba(0, 0, 0, 1) 20%); */
}
.cashoutTokensScreen {
  position: relative;
  width: 100%;
  top: 0;
  height: 0;
  visibility: hidden;
  overflow: auto;
}
.cashoutTokensContent,
.transactionTokensContent {
  padding-bottom: 30px;
}
.transactionText {
  display: flex;
  align-self: flex-start;
  align-items: end;
  flex-direction: column;
  min-width: 66px;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin-right: -5px;
  color: var(--sec-text-color);
}
.transactionCardTime {
  display: flex;
  /* align-self: flex-start;
  align-items: end; */
  flex-direction: column;
  min-width: 66px;
  font-size: 13px;
  letter-spacing: 0px;
  text-align: left;
  margin-right: -5px;
  color: var(--sec-text-color);
}
.tokenSelect {
  cursor: pointer;
}
.stakeSelection-Token {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: auto;
  /* height: 8vh; */
  min-height: 70px;
  max-height: 80px;
  margin: 2% 5% 5% 5%;
  /* background-color: rgba(0, 0, 0, 0.185); */
  border-radius: 10px;
  overflow: hidden;
  background: rgb(38 38 38 / 33%);
  background: var(--card-gradient-background2);
  box-shadow: var(--card-box-shadow);
  transition: 0.3s;
}
.stakeSelection-Token:hover {
  background: var(--card-gradient-background-hover);
  transform: scale(1.02);
}
.stakeSelection-TokenInfo {
  position: relative;
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.stakeSelection-Winnings {
  display: flex;
  min-width: 42px;
  padding: 0px 10px 0px 10px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.295);
  background: var(--background-darker);
  color: #2dbd7e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.token-properties {
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  padding: 0px 10px 0px 10px;
  margin-top: -11px;
  box-sizing: border-box;
  /* background: #222222; */
  color: gray;
  color: var(--sec-text-color);
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
  transition: 0.3s;
}
.token-properties span {
  color: white;
}

.stakeSummary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
  font-size: 16px;
  box-sizing: border-box;
  background: #222222;
  background: var(--card-gradient-background);
  box-shadow: var(--card-box-shadow);
  color: var(--sec-text-color);
  border-radius: 7px;
  transition: 0.3s;
  /* cursor: pointer; */
}
.stakeSummary:hover {
  background: var(--card-gradient-background-hover);
  transform: scale(1.02);
}
.stakeSummary-item {
  display: flex;
  justify-content: space-between;
}
.stakeSummary-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.stakeSummary-diamond {
  position: relative;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 0;
  width: 7px;
  height: 7px;
  background: var(--w3m-accent-color);
  /* top: 0; */
  /* left: 0; */
  margin-top: -3px;
  margin-right: 10px;
  -webkit-transform: scale(1);
  transform: scale(1) rotate(45deg);
}

.stakeSummary-Line {
  width: 100%;
  height: 1px;
  background: #414141;
  background: #282e3f;
  background: #282e3f7a;
  align-self: center;
}
.stakeSummary-ValueDate {
  color: white;
}
.stakeSummary-TotalRewards .stakeSummary-ValueDate {
  color: #2dbd7e;
}

.stakeConfirmBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 100%; */
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px 10px;
  box-sizing: border-box;
  background: #222222;
  color: gray;
  background: #494949;
  color: #7a7878;
  background: #161b29;
  color: #333848;
  font-size: 20px;
  border-radius: 4px;
  transition: 0.3s;
  cursor: not-allowed;
}
.stakeConfirmBtnActive {
  background: var(--w3m-accent-color);
  color: black;
  cursor: pointer;
}
.stakeConfirmBtnActive:hover {
  transform: scale(1.02);
}
.cashoutBottomCont {
  position: absolute;
  display: flex;
  flex-flow: column;
  gap: 20px;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* padding-top: 30px; */
  padding-bottom: 20px;
  width: 100%;
  bottom: 0;
}
.cashoutBtn-Line {
  width: 100%;
  height: 1px;
  margin-top: 50px;

  background: #414141;
  background: #282e3f;
  background: #282e3f7a;
  align-self: center;
}
.cashoutConfirmBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 100%; */
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px 10px;
  min-width: 130px;
  box-sizing: border-box;
  background: #222222;
  color: gray;
  background: #494949;
  color: #7a7878;
  background: #161b29;
  color: #333848;
  font-size: 20px;
  border-radius: 4px;
  transition: 0.3s;
  cursor: not-allowed;
}
.cashoutConfirmBtnActive {
  background: var(--w3m-accent-color);
  color: black;
  cursor: pointer;
}
.cashoutConfirmBtnActive:hover {
  transform: scale(1.02);
}
.cashoutBtnCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cashoutBtnLeft {
  display: flex;
  flex-flow: column;
  gap: 4px;
  width: 100%;
  padding: 0px 10px 0px 10px;
  /* margin-top: -11px; */
  box-sizing: border-box;
  /* background: #222222; */
  color: gray;
  color: var(--sec-text-color);
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
  transition: 0.3s;
}
.cashoutBtnReceiveAmount {
  font-size: 18px;
  color: white;
}
.rulesScreen {
  position: relative;
  width: 100%;
  top: 0;
  height: 0;
  overflow: auto;
  visibility: hidden;
}

.RulesContent {
  display: flex;
  flex-flow: column;
  gap: 20px;
  padding-bottom: 30px;
  /* max-height: 75vh;
  padding-top: 55px;
  margin-top: -35px;
  overflow: auto;
  mask-image: linear-gradient(180deg, transparent 5%, rgba(0, 0, 0, 1) 20%);
  -webkit-mask-image: linear-gradient(180deg, transparent 5%, rgba(0, 0, 0, 1) 20%); */
}
.rulesContent {
  padding: 10px;
}
.rulesBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  /* background: #222222; */
  font-size: 17px;
  /* border-radius: 4px; */
  transition: 0.3s;
  text-align: center;
}
.rulesBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222222;
  background: var(--background-lighter);
  color: white;
  flex: 1;
  padding: 6px 2px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
}
.rulesBtn:hover {
  background: var(--background-lighter-hover);
}
.ruleBtnActive {
  border-color: var(--w3m-accent-color);
}

.menu-container:before {
  content: "";
  pointer-events: none;
  user-select: none;
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(to bottom, #ffcb0f, #fff0);
  mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask: linear-gradient(black, black) content-box content-box, linear-gradient(black, black);
  -webkit-mask-composite: xor;
  mask-composite: xor;
  z-index: 5;
}

.mainMenuOverlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #00000087;
  z-index: 2999;
  visibility: hidden;
  transform: translateY(100%);
}
.NotificationsOverlay {
  position: absolute;
  width: 100%;
  top: 0;
  height: 0;
  overflow: auto;
  height: 100%;
  /* visibility: hidden; */
  margin: -9px 0px 0px -9px;
  border-radius: 15px 15px 0 0;
  background: var(--main-background-solid);
  background: #04061100;
  backdrop-filter: blur(0px);
  visibility: visible;
  pointer-events: none;
  /* transform: translateX(15%); */
}
.drag-layer {
  position: absolute;
  width: 50%;
  /* left: 0; */
  right: 0;
  margin: auto;
  height: 100%;
  z-index: 10;
  background: #ff000004;
  display: none;
  /* pointer-events: none; */
}
.notifications {
  position: absolute;
  width: 100%;
  top: 0;
  /* height: 0; */
  overflow: hidden;
  height: 100%;
  /* visibility: hidden; */
  margin: -0px 0px 0px -9px;
  border-radius: 15px 15px 0 0;
  /* background: var(--main-background-solid); */
  transform: translateX(100%);
}
.notifications::-webkit-scrollbar {
  display: none;
}
/* .norificationsContent {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  color: gray;
  padding: 20px;
  font-size: 21px;
  box-sizing: border-box;
} */
/* .notificationsContent {
  padding: 30px 0px;
} */
.notificationsCards {
  position: relative;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  gap: 10px;
  width: auto;
  height: 70px;
  /* min-height: 70px; */
  /* max-height: 80px; */
  margin: 2% 5% 5% 5%;
  margin: 25px 25px 25px 25px;
  padding-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  font-family: "inter";
  overflow: hidden;
  background: var(--card-gradient-background2);
  background: var(--card-gradient-background-hover);
  background: linear-gradient(#080b13c1 0%, #111829d6 100%);
  box-shadow: var(--card-box-shadow);
  transition: 0.3s;
  cursor: pointer;
}
.transactionsCard {
  height: unset;
  height: 44px;
}
.notificationsCards:hover {
  background: var(--card-gradient-background-hover);
  transform: scale(1.02);
}
.notificationsCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.notificationsCardHeadline {
  font-size: 14px;
  font-weight: 700;
  width: 71%;
  /* color: green; */
}
.transactionsCardHeadline {
  font-size: 14px;
  font-weight: 700;
  /* width: 67%; */
  /* color: green; */
}
.notificationsCardTime {
  display: flex;
  align-self: flex-start;
  align-items: end;
  flex-direction: column;
  min-width: 66px;
  font-size: 14px;
  letter-spacing: -1px;
  text-align: left;
  white-space: nowrap;
  margin-right: -5px;
  color: var(--sec-text-color);
}
.notificationsText {
  font-size: 14px;
  color: var(--sec-text-color);
}
.notiloadMore,
.announcementNotiloadMore,
.transactionloadMore {
  width: fit-content;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  padding: 10px 20px;
  height: unset;
}
.notiloadMore {
  display: none;
}
.transactionloadMore {
  display: none;
}
.notiheader {
  position: relative;
  top: 0;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: -7px;
  margin-bottom: -30px;
  padding-bottom: 40px;
  /* background-image: linear-gradient(to bottom, rgb(6 7 18) 60%, rgb(6 7 18 / 62%) 75%, rgb(0 0 0 / 0%)); */
}
.menueBackFromNoti {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-size: 22px;
  padding: 25px 20px 10px 20px;
}

.notiBtns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* gap: 5px; */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  /* background: #222222; */
  font-size: 18px;
  /* border-radius: 4px; */
  transition: 0.3s;
  text-align: center;
}
.notiBtn {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  /* background: #222222; */
  /* background: var(--background-lighter); */
  color: white;
  color: var(--sec-text-color);
  flex: 1;
  padding: 6px 0px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
}
.notBtn1 {
  color: white;
}
.notiBtnIndecator {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: transparent;
  border-radius: 100%;
}
.notBtnUnderLine {
  display: block;
  width: 40px;
  height: 2px;
  margin-top: 5px;
  background: transparent;
  border-radius: 15px;
}
.notiBtnActive1 {
  background: var(--w3m-accent-color);
}
.notiBtnActive2 {
  background: transparent;
}
.notiCardsContainer {
  position: relative;
  -webkit-mask-image: linear-gradient(180deg, transparent 4%, rgba(0, 0, 0, 1) 11%);
  mask-image: linear-gradient(180deg, transparent 4%, rgba(0, 0, 0, 1) 11%);
  /* position: absolute; */
  margin-top: -70px;
  padding-top: 25px;
  height: 100%;
  overflow-x: hidden;
}
.notiCardsContainer::-webkit-scrollbar {
  display: none;
}
.notificationsContent {
  padding-bottom: 140px;
  position: absolute;
  width: 100%;
}
.announcementNotificationsContent {
  transform: translateX(100%);
  width: 100%;
  padding-bottom: 140px;
  position: absolute;
}
.earningsMenuScreen {
  position: relative;
  width: 100%;
  /* min-height: 0; */
  top: 0;
  height: 0px;
  overflow: auto;
  overflow-x: hidden;
  visibility: hidden;
  opacity: 0;
}
.earningsMenuContent {
  position: absolute;
  display: flex;
  flex-flow: column;
  gap: 25px;
  padding: 10px;
  width: 95%;
  /* padding: 10px 10px 10px 10px; */
}
.earningsBtns {
  display: none;
}
.APYValue {
  flex: 0 0 50%;
}
.earningsTotalValueText {
  font-size: 15px;
  margin-bottom: 6px;
  color: var(--sec-text-color);
}
.earningsTotalValueGPO {
  display: flex;
}
.earningsTotalValueGPOText {
  font-size: 21px;
}
.APYValueText {
  font-size: 21px;
}
.earningsTotalValueGPO span {
  font-size: 14px;
  color: var(--sec-text-color);
  padding-left: 5px;
  align-self: end;
  padding-top: 4px;
}
.earningsHeader {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}
.earnings30Day {
  flex: 0 0 auto;
}
.earningsLastDay {
  flex: 0 0 50%;
  /* margin-left: 100px; */
}
.earnings30DayGPO {
  color: #2dbd7e;
  font-size: 17px;
}
.earningsGPO {
  font-size: 14px;
  color: var(--sec-text-color);
  padding-top: 4px;
}
.earningsStakedTokens {
  display: flex;
  flex-flow: column;
  gap: 25px;
}
.noStakedTokens {
  text-align: center;
  margin-top: 50px;
  color: var(--sec-text-color);
}
.stakedToken {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: auto;
  /* height: 8vh; */
  /* min-height: 70px; */
  /* max-height: 80px; */
  /* margin: 2% 5% 5% 5%; */
  padding: 20px 0px;
  /* background-color: rgba(0, 0, 0, 0.185); */
  border-radius: 10px;
  overflow: hidden;
  background: rgb(38 38 38 / 33%);
  background: var(--card-gradient-background2);
  box-shadow: var(--card-box-shadow);
  transition: 0.3s;
}
.stakedToken:hover {
  background: var(--card-gradient-background-hover);
  transform: scale(1.02);
}
.stakedPeriod {
  background-color: #13192d;
  color: var(--w3m-accent-color);
  padding: 3px;
  margin-left: 5px;
  margin-top: -4px;
  font-size: 12px;
  box-sizing: border-box;
  padding: 4px 4px 2px 4px;
  /* border-radius: 6px; */
  line-height: 12px;
}

.stakedPeriod2 {
  background-color: #ffcc0f1a;
  color: var(--w3m-accent-color);
  padding: 3px;
  margin-left: 5px;
  margin-top: -4px;
  font-size: 12px;
  box-sizing: border-box;
  background: linear-gradient(93.3deg, rgb(203, 184, 255) 0%, rgb(128, 125, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #180d0d00;
  color: unset;
  border: 1px solid #13192d;
  padding: 5px 8px 3px 8px;
  border-radius: 6px;
  line-height: 12px;
}
.stakedCumulativeInterest {
  display: flex;
  gap: 5px;
  min-width: 42px;
  padding: 0px 10px 0px 10px;
  flex-flow: column;
  justify-content: center;
  align-items: end;

  color: #2dbd7e;
}
.stakedInfo {
  display: flex;
  flex-flow: column;
  gap: 5px;
}
.levestakedInfolInfo {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.stakedCumulativeInterestText {
  color: var(--sec-text-color);
  font-size: 15px;
}
.stakedCumulativeInterestValue {
  color: white;
  font-size: 15px;
}
.stakedAprText {
  padding-left: 20px;
  color: var(--sec-text-color);
  font-size: 15px;
}
.StakedAprValue {
  padding-left: 20px;
  /* color: #2dbd7e; */
  font-size: 15px;
}
.earningsBtn {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  /* background: #222222; */
  /* background: var(--background-lighter); */
  color: white;
  color: var(--sec-text-color);
  /* flex: 1; */
  padding: 6px 0px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
}
.earningsBtn1 {
  color: white;
}
.earningsBtn2 {
  color: var(--sec-text-color);
}
#PieChartContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#pieChart {
  width: 50% !important;
  height: 50% !important;
}

.piePrincipal,
.pieStakeRewards,
.pieGameWinnings {
  /* width: 50%; */
  padding: 10px;
  text-align: center;
  color: var(--sec-text-color);
  transition: 0.3s;
  background-color: transparent;
}
.piePrincipal:hover,
.pieStakeRewards:hover,
.pieGameWinnings:hover {
  /* width: 50%; */
  transform: scale(1.02);

  background-color: var(--background-lighter-hover);
}
.piePrincipal span,
.pieStakeRewards span,
.pieGameWinnings span {
  display: block;
  color: white;
}
.lineChartBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;
  /* background: #222222; */
  font-size: 17px;
  /* border-radius: 4px; */
  transition: 0.3s;
  text-align: center;
}
.lineChartBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222222;
  background: var(--background-lighter);
  color: var(--sec-text-color);
  /* flex: 1; */
  padding: 6px 25px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  /* box-shadow: var(--card-box-shadow); */
  border: 1px solid transparent;
  transition: 0.3s;
}
.lineChartBtn:hover {
  transform: scale(1.02);

  background: var(--background-lighter-hover);
}
.lineChartBtnActive {
  color: white;
}
.innerMenueBackFromEarnings {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* top: 0; */
  width: 100%;
  /* box-sizing: border-box; */
  /* font-size: 22px; */
  padding: 0px 10px 25px 10px;
  margin-bottom: -7px;
}
.menuBackEarnings {
  flex-direction: column;
  padding: 15px 10px 60px 10px;
  margin-bottom: -42px;
  padding: 15px 10px 35px 10px;
  margin-bottom: -15px;
}
.GameAnalysisContent {
  position: absolute;
  display: flex;
  flex-flow: column;
  gap: 35px;
  padding: 10px;
  width: 95%;
  transform: translateX(100%);
}
.notBtnUnderLine1 {
  background: var(--w3m-accent-color);
}
.notBtnUnderLine2 {
  background: transparent;
}
span.assetTrendInfo {
  display: inline-block;
  background: #5e6287;
  width: 13px;
  height: 13px;
  font-size: 13px;
  font-family: "inter";
  font-weight: 700;
  line-height: 13px;
  padding-left: 1px;
  margin-bottom: 1px;
  box-sizing: border-box;
  color: #070811;
  /* padding: 10px; */
  border-radius: 100%;
  text-align: center;
  align-self: center;
}
.assetTrendText {
  display: flex;
  flex-direction: row;
  /* flex-wrap: nowrap; */
  /* align-content: center; */
  align-items: flex-start;
  gap: 5px;
}

.quickMenu {
  position: relative;
  /* display: none; */
  display: flex;
  justify-content: space-between;
  padding: 0px 7px;
  margin-top: 5px;
  top: 20px;
  width: 100%;
}
.quickMenuElement {
  /* flex: 1; */
  cursor: pointer;
  transition: 0.3s;
}
.quickMenuElement:hover {
  transform: scale(1.1);
}
.quickMenuLabel {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 15px;
  font-family: "inter";
  font-size: 10px;
  color: white;
  flex-direction: column;
}
.quickMenuBtnCont {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-left: 5px; */
  /* margin-left: 10px; */
  /* margin-right: 5px; */
  background: #ffffff1c;
  background: rgb(4 6 17 / 60%);
  background: rgb(4 6 17 / 50%);
  border-radius: 100%;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
}
.quickMenuBtnCont svg {
  width: 25px;
  height: 25px;
  fill: #707989;
  /* fill: white; */
}
.notificationsIconCountQuickMenu {
  position: absolute;
  background: red;
  padding: 4px;
  margin-top: -20px;
  margin-left: 26px;
  margin-top: -28px;
  margin-left: 9px;
  width: 20px;
  height: 20px;
  font-family: "Jost";
  font-size: 15px;
  border-radius: 100%;
  text-align: center;
  box-sizing: border-box;
}
.levelsSpinner {
  position: absolute;
  display: flex;
  width: 100%;
  top: 13vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  scale: 0.5;
}

/* .loader {
  width: 48px;
  height: 48px;
  border: 3px solid #707989;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--w3m-accent-color);
  width: 16px;
  height: 16px;
  transform: translate(-50%, 50%);
  border-radius: 50%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

/* .loader {
  transform: translateZ(1px);
}
.loader:after {
  content: "$";
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 32px;
  font-weight: bold;
  background: #ffd700;
  color: #daa520;
  border: 4px double;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
  animation: coin-flip 4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes coin-flip {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
} */

/* .loader {
  font-size: 48px;
  color: #fff;
  color: var(--w3m-accent-color);
  width: 1em;
  height: 1em;
  box-sizing: border-box;
  background-color: currentcolor;
  position: relative;
  border-radius: 50%;
  transform: rotateX(-60deg) perspective(1000px);
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  animation: flowerFlow 1s ease-out infinite;
}
.loader:after {
  animation-delay: 0.4s;
}

@keyframes flowerFlow {
  0% {
    opacity: 1;
    transform: rotate(0deg);
    box-shadow: 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor,
      0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor;
  }
  100% {
    opacity: 0;
    transform: rotate(180deg);
    box-shadow: -1em -1em 0 -0.35em currentcolor, 0 -1.5em 0 -0.35em currentcolor, 1em -1em 0 -0.35em currentcolor,
      -1.5em 0 0 -0.35em currentcolor, 1.5em -0 0 -0.35em currentcolor, -1em 1em 0 -0.35em currentcolor, 0 1.5em 0 -0.35em currentcolor,
      1em 1em 0 -0.35em currentcolor;
  }
}
 */

.loader {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
}

.loader:before,
.loader:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
}
.loader:after {
  box-shadow: 0 2px 0 var(--w3m-accent-color) inset;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.levelSkeletonCont {
  width: 95%;
  position: absolute;
  top: 6vh;
  top: 60px;
  right: 0;
  margin-top: 2.5%;
}
.LevelSkeleton {
  /* position: absolute; */
  width: 96%;
  height: 64px;
  /* min-height: 60px; */
  margin: 8px;
  background-color: rgba(0, 0, 0, 0.185);
  background: rgba(0, 0, 0, 0.329);
  /* background-color: #040611; */
  /* background-color: #0c1121; */
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.329);
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.259) 50%, rgba(255, 255, 255, 0) 60%)
    rgba(0, 0, 0, 0.329);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
.notiSkeletonCont {
  width: 100%;
  position: absolute;
  /* z-index: 5; */
  visibility: hidden;
}
.notiSkeleton {
  height: 64px;

  margin: 25px;
  padding: 20px;

  border-radius: 10px;
  overflow: hidden;
  height: 70px;
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 60%) #0e1320c1; /* 0d121f00 */
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
.transactionsSkeleton {
  width: 100%;
  position: absolute;
  /* z-index: 5; */
  visibility: hidden;
}
.transactionsSkeleton .notiSkeleton {
  margin: 25px;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  height: 44px;
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 60%) #0e1320c1;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.scrollbutton {
  /* margin-left: 24rem; */
  /* bottom: 32rem; */
  width: 100%;
  height: 100vh;
  height: 50vh;
  top: 25vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 98px;
  justify-content: center;
  /* bottom: 1vh; */
  /* margin-left: 1vh; */
  align-items: end;
  /* bottom: 40vh; */
  right: 1vh;
  z-index: 6;
}
.scrollUpIcon {
  rotate: 180deg;
  visibility: hidden;
}
.scrollDownIcon {
  transform: translateY(30vh);
  transform: translateY(25vh);
}
.scrollbutton .scrollIcon {
  position: relative;
  width: 38px;
  height: 38px;
  cursor: pointer;
  visibility: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}

.circle-svg .circle {
  stroke: var(--w3m-accent-color);
  /* stroke: white; */
}
.scrollIcon:hover .circle {
  stroke: var(--w3m-accent-color);

  stroke-opacity: 0.6;
}
.scrollbutton .scrollIcon .bars {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 2px;
  height: 70px;
  left: 0;
  right: 0;
  bottom: calc(50% - 2px);
  margin: 0 auto;
  overflow: hidden;
}
.scrollbutton .scrollIcon .bar {
  top: 0;
  position: absolute;
  width: 1px;
  height: 100%;
  background-image: linear-gradient(0deg, rgba(195, 140, 92, 0) 0%, var(--w3m-accent-color) 20%, rgba(195, 140, 92, 0) 100%);
  background-size: 100% 120%;
  background-repeat: no-repeat;
  opacity: 0;
}
.bar-1 {
  animation: 10s ease 0s infinite normal none running barBackground;
}
.bar2 {
  animation-delay: 5s;
  animation: 10s ease 5s infinite normal none running barBackground;
}
.scrollbutton .scrollIcon .circle-svg {
  width: 100%;
  height: 100%;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  transform: rotate(-90deg);
}
.scrollbutton .scrollIcon .arrow-svg {
  width: 25%;
  height: 25%;
  transform: rotate(-90deg);
  stroke-width: 0.7;
}
.scrollbutton .scrollIcon .absolute-svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
svg.arrow-svg.absolute-svg path {
  stroke: var(--w3m-accent-color);
}
@keyframes barBackground {
  0% {
    background-position-y: -70px;
    transform: translateZ(0);
    opacity: 0;
  }

  20% {
    opacity: 1;
    background-position-y: 20px;
    transform: translateZ(0);
  }

  80% {
    transform: translate3d(0, 20px, 0);
    opacity: 1;
  }

  95% {
    background-position-y: 50px;
    opacity: 1;
  }

  to {
    background-position-y: 70px;
    transform: translate3d(0, 80px, 0);
    opacity: 1;
  }
}

.connectedAddressNotiCont {
  position: fixed;
  /* width: 100%; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-content: center; */
  /* align-items: stretch; */
  /* justify-content: space-between; */
  margin: auto;
  left: 0;
  right: 0;
  /* top: 6%; */
  /* top: 70px; */
  top: -70px;
  /* bottom: 65px; */
  /* bottom: -65px; */
  width: 260px;
  z-index: 1001;
}
.connectedAddressNoti {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  padding: 6px 4px 6px 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 30px;
  backdrop-filter: blur(5.2px);
  -webkit-backdrop-filter: blur(5.2px);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  white-space: nowrap;
  --w3m-accent-fill-color: #ffffff;
  --w3m-font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  --w3m-font-family: "Jost";
  --w3m-text-medium-regular-weight: 600;
  --w3m-text-medium-regular-letter-spacing: unset;
  --w3m-text-medium-regular-size: 18px;
  --w3m-text-medium-regular-size: 22px;
  font-size: var(--w3m-text-medium-regular-size);
  font-family: var(--w3m-font-family);
}
.connectedAddressNoti .sortingContBtn {
  margin-left: 0px;
}
.connectedAddressNotiMargin {
  margin-top: 5px;
}
/* .w3m-medium-regular {
  font-family: var(--w3m-text-medium-regular-font-family);
  font-weight: var(--w3m-text-medium-regular-weight);
  font-size: var(--w3m-text-medium-regular-size);
  line-height: var(--w3m-text-medium-regular-line-height);
  letter-spacing: var(--w3m-text-medium-regular-letter-spacing);
  text-transform: var(--w3m-text-medium-regular-text-transform);
} */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .alignStart {
    margin-left: 65px;
    margin-top: 6px;
  }
}

.headerFlex {
  display: flex;
  gap: 10px;
  margin-right: 2%;
}
.headerWalletAdress {
  display: none;
  flex-direction: column;
  /* width: 130px; */
  font-family: "inter";
  font-size: 12px;
  font-family: "Jost";
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 30px;
  backdrop-filter: blur(5.2px);
  -webkit-backdrop-filter: blur(5.2px);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  opacity: 0;
}
.copyWalletAddressBtn {
  display: flex;
  gap: 6px;
  align-items: center;
  /* text-shadow: 1px 1px 3px rgb(0 0 0 / 76%); */
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.76));

  /* font-size: 12px; */
  white-space: nowrap;
}
.copiedWalletAddress {
  display: none;
}
.referralWalletAddrerss {
  font-size: 12px;
  word-wrap: break-word;
  text-align: center;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 76%);
}
.totalReferralsHeader {
  text-shadow: 1px 1px 3px rgb(0 0 0 / 76%);
  /* font-size: 12px; */
}

.counter-container {
  position: fixed;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 100vh; */
  /* width: 100vw; */
  /* bottom: 18vh; */
  bottom: 15vh;
  /* right: 2vw; */
  /* right: 6vw; */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  font-family: "inter";
  /* visibility: hidden; */
  /* opacity: 0; */
}

.half-circle {
  width: 200px;
  height: 100px;
  background-color: transparent;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border: 5px solid yellow;
  border-bottom: 0;
  position: relative;
}

.counterDown-display {
  position: absolute;
  font-size: 29px;
  line-height: 28px;
  white-space: nowrap;
  color: transparent;
  top: 67%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(90deg, #ff4d4d, #f9cb28);
  background: linear-gradient(90deg, #332800, #f9cb28);
  /* background: white; */

  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  clip-path: inset(1px);
}
.counterDateTime {
  position: absolute;
  font-size: 13px;
  text-align: center;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: #707989; */
}
.days-left-display {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: #707989; */
}
.prepareBtn {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 100%; */
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  padding: 15px 10px;
  box-sizing: border-box;
  background: #222222;
  color: gray;
  background: #494949;
  color: #7a7878;
  background: #161b29;
  color: #333848;
  font-size: 20px;
  border-radius: 4px;
  transition: 0.3s;
  cursor: not-allowed;
  background: var(--w3m-accent-color);
  background: transparent;
  border: 2px solid var(--w3m-accent-color);
  color: black;
  color: white;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5vh;
  font-family: "inter";
  z-index: 100;

  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(calc(var(--btn-grad-red-deg) * 1deg), #1f1500, #fca40a 50%, #1f1500);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000212 inset;
  border-radius: 8px;
  border: solid 2px transparent;
  --btn-grad-red-deg: 338;
}
.counterAndBtnCont {
  visibility: hidden;
}
.policy {
  position: fixed;
  font-family: "inter";
  font-size: 13px;
  z-index: 100;
  color: gray;
  bottom: 0;
  margin: 10px;
  visibility: hidden;
  opacity: 0;
}
.policy a {
  color: gray;
}
.policyTitle {
  padding: 10px;
  font-family: "inter";
  font-size: 24px;
  font-weight: 600;
  color: white;
}
